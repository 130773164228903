import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import background from "../../Figures/PRO004_Banner.jpg"
import systemDiagram from "../../Figures/PRO004_SystemDiagramBattery.jpg"
import pageLayout from "../pages.module.css"
import ProjectOverview from "../../components/ProjectOverviews/PRO004Overview.js"
import TarmoPrev from "../../components/TarmoPrev"
import ServoRange from "../../Figures/ServoRange.png"
import SteeringServo from "../../Figures/SteeringServo.jpg"
import WheelRotation from "../../Figures/WheelRotatingRange.jpg"



export default function PRO004_ProjectRequirements() {
  return (
      <div>
      <div>
    <Layout>
    </Layout>
    <div  style={{backgroundImage: "url(" + background + ")" ,backgroundSize: "cover", minHeight: "150px",zIndex:"1"}}>
      <div className={pageLayout.text}>
      <h1 style={{color: "white"}}>PRO004 - Home Battery DYI</h1>
      <br />
      <h2 style={{color: "white"}}>Project requirements</h2>
        </div>
      </div>
      </div>
      <div style={{}}>
      <div className={pageLayout.text}>
        <h2>What?</h2>
          <p>As many Belgian people, last year I rushed to get some solar panels on the roof, lured by the discussion that 2020 would be the last year that you shall still be able to enjoi a energy meter that counts backwards if you have overproduction.
            <br />
            We also got a digital meter and in March this year it became clear that the digital meters, although capable of counting backwards when there was over production, would no longer do so. Now if we have over production we can sell that back to the energy company for 16% of what we for it.
          </p>
          <p>So, I want to instal one or more smaller home battery modules, which do not connect to the main fuse box of the home, but where you can connect consumers to and which can be charged from a wall-socket when there is over production. what this would look like schematically you can see on the figure below.
            <br />
            In this section I'll list some of the requirements I want the module to adhere to and some intermediate challenges I want to overcome while making the complete system.
          </p>
          <figure>
        <img width="70%"  src={systemDiagram} style={{borderRadius: 20, marginLeft: "15%" , marginRight: "15%"}} alt="Home battery DIY model system diagram." />
        <figcaption style={{textAlign: "center"}}>Fig.1 - System diagram and concept of the intended Home Battery module.</figcaption>
        </figure>
        <h2>Project requirements</h2>
        <p> Below is a limited list with some of the main characteristics I want the system to have.</p>
        <p>
          <ol>
            <li>The system shall provide an interface to receive actual energy consumption of the house through the mqtt protocol.</li>
            <li>The system shall be able to monitor the battery SOC when the system is not charging and report this SOC.</li>
            <li>The system shall have at least one 240 VAC european socket as output.</li>
            <li>The system shall be able to get charged though a standard 240 VAC socket.</li>
            <li>The system shall only charge the battery when there is overproduction from the solar cells or if the SOC is below 10%.</li>
            <li>The system shall be able to change between supplying the consumers by battery or directly by the mains in less than 4 ms.</li>
            <li>Shall provide a pure sine wave on the mains plug output.</li>
          </ol>
        </p>
        <h2>Intermittent milestones</h2>
        <p>There are a few things, probably a lot more, that I am not </p>
        <ul>
          <li>Obtaining a bidirectional interface between HomeAssistant and the battery controller to send battery data and receive energy consumption information.</li>
          <li>Swapping between battery supply and mains supply to the output should not interrupt normal operation of the connected consumers.</li>

        </ul>
        </div>
      <div className={pageLayout.side}>
        <h2 style={{}}>Project overview:</h2>
      <ProjectOverview></ProjectOverview>
      </div>
      </div>
    </div>
  )
}